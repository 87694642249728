<template>
  <div>
    <div class="head_section">
      <h2 id="dkBody" class="tit_subdesc">
        <span class="txt_number">요청번호 : {{ docNo }}</span>
        <span class="txt_info">{{ _.unescape(itemData.docTitle) }}</span>
      </h2>
    </div>
    <!-- 결재현황 -->
    <div class="section_g">
      <PayStatus
        :approval-line="approvalLine"
        :doc-no="docNo"
        :doc-flag="docFlag"
        :isViewer="true"
        :viewerList.sync="viewerList"
        :dateTimeFormat="dateTimeFormat"
        @searchOrganization="searchOrganization"
        @popDeleteViewer="popDeleteViewer"
      />
    </div>
    <!-- 승인 반려 코멘트 -->
    <RejectComment v-if="whetherExistComment" :approval-line="approvalLine" />
    <!-- 구매요청 기본정보 -->

    <DraftReqeustInfo :draftData="itemData" />
    <!-- 구매요청품목 -->

    <!-- PdfViewer -->
    <PdfViewer v-if="itemData.targetPk" :pdfInfoObj="itemData.pdfHistoryResult" />
    <!-- 첨부파일 -->
    <DraftFile :attachFileList.sync="model.attachFileList" />
    <div class="wrap_btn type_bottom">
      <div class="pos_left">
        <button type="button" class="btn_large btn_fourthly" @click.prevent="gotoList">목록</button>
      </div>

      <div class="pos_right"></div>
    </div>
    <PopPriceAdd
      v-if="isPopPriceAdd"
      :priceAddTargetData="priceAddTargetData"
      @onClickClose="onClickClosePopPriceAdd"
      @onClickComplete="onClickCompletePopPriceAddBtn"
    />
    <PopPriceAddHistory
      v-if="isPopPriceHistory"
      :histories="priceAddHistories"
      @onClickClose="onClickClosePopPriceAddHistory"
    />
    <PopStatementHistory
      v-if="isPopStatementHistory"
      :data-list="statementHistory"
      @onClickClose="onClickClosePopStatementHistory"
    />
  </div>
</template>

<script>
import Radio from "@/_approval/components/common/radio/Radio";
import axios from "axios";
import { mapState } from "vuex";
import PayStatus from "@/_approval/components/shared/view/PayStatus";
import RejectComment from "@/_approval/components/shared/view/RejectComment";
import DraftReqeustInfo from "@/_approval/components/payment/view/DraftReqeustInfo";
import DraftPurchaseGoodsList from "@/_approval/components/payment/view/DraftPurchaseGoodsList";
import DraftPurchasePayment from "@/_approval/components/payment/view/DraftPurchasePayment";
import DraftFile from "@/_approval/components/payment/view/DraftFile";
import MyComment from "@/_approval/components/shared/view/MyComment";
import PurchaseRequestDetailMixin from "@/_approval/components/shared/view/PurchaseRequestDetailMixin";
import DraftWriteModel from "@/_approval/pages/payment/write/DraftWriteModel";
import commDocs from "@/_approval/mixins/common/commDocs";
import {
  assetType,
  approvalStatus,
  aprvlLineType,
  docStatus,
  targetGubun,
} from "@/_approval/const/const";
import ApiService from "@/services/ApiService";

import LocalStorageManager from "@/LocalStorageManager";
import PdfViewer from "@/components/shared/PdfViewer";

export default {
  components: {
    Radio,
    PayStatus,
    RejectComment,
    DraftReqeustInfo,
    DraftPurchaseGoodsList,
    DraftPurchasePayment,
    DraftFile,
    MyComment,
    PdfViewer,
  },
  mixins: [PurchaseRequestDetailMixin, commDocs],
  data() {
    return {
      apprType: "",
      apprReason: "",
      nowApprovalInfo: {
        accountId: "",
      },
      docNo: this.$route.params.id,
      itemData: {},
      itemId: "",
      targetPk: "",
      targetGubun: "",
      docStatus: Object.freeze(docStatus),
      partReject: false, // 부분반려 true/false
      model: new DraftWriteModel(),
      approvalLine: [],
      canAproveEdit: false,
      assetType: assetType,
      docFlag: "AC",
    };
  },
  computed: {
    isAdmin() {
      return LocalStorageManager.shared.getIsAdmin();
    },
    isNotSuperOrAdmin() {
      return (
        LocalStorageManager.shared.getIsInspect() ||
        LocalStorageManager.shared.getIsRollUser() ||
        LocalStorageManager.shared.getIsRollUser().getIsPartner
      );
    },
  },
  created() {
    this.getData();
    this.updateRead();
  },
  // async mounted() {

  //   this.updateRead();
  // },
  methods: {
    async getData() {
      this.itemId = this.$route.params.id;
      const path = `${this.$apiPath.APRVL_DOC_LIST}/STD/${this.itemId}`;

      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;

      this.itemData = data;
      this.docNo = data.docNo;
      this.targetGubun = data.targetGubun;
      this.approvalLine = data.aprvlLineList;
      this.model = data;
      return;
    },

    async updateRead() {
      const { docStatus, aprvlStatus } = this.docFlagToCode(this.docFlag);

      const params = {
        targetGubun: targetGubun.STANDARD,
        docNoList: [this.docNo],
        readYn: true,
        docStatus,
        aprvlStatus,
      };

      const res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_READ, params);
    },
    gotoList() {
      this.$router.push(this.$paymentRouterPath.PAYMENT_APPROVAL_COMPLETE);
    },
    async initData() {
      const data = await this.fetchStoredDraftData(this.docNo);

      this.model.initFromFetchData(data);
      this.model.deptPathName = await this.fetchDeptPathNameByDeptCode(data.drftDeptCode);
      this.approvalLine = data.aprvlLineList;
    },
    async onChangeAccountYnStatus(changedValue) {
      const completed = await this.accountYnStatus(changedValue);
      if (completed) {
        await this.initData();
      }
    },
    canApprove() {
      return this.getCurrentApprover().accountId === this.accountId;
    },
    getCurrentApprover() {
      return this.approvalLine.find(
        (e) =>
          e.aprvlLineType === aprvlLineType.APPROVAL &&
          e.aprvlStatus === approvalStatus.RECEIVE_PAYMENT,
      );
    },
    async onClickCompletePopPriceAddBtn(addPriceData) {
      if (await this.onClickCompletePopPriceAdd(addPriceData)) {
        await this.initData();
      }
    },
    async fetchStatementHistory() {
      const res = await ApiService.shared.getData(
        `${this.$apiPath.SETTLEMENT_PURCHASEHISTORY}/${this.docNo}`,
      );
      if (!this.checkNormalResponse(res)) {
        return;
      }

      if (res.data) {
        this.statementHistory = res.data.history;
        this.statementTotalPrice = res.data.totalPrice;
      }
    },
    async onClickAnnounceRegister() {
      const number = await this.getNewAnnounceNumber();
      const path = `${this.$routerPath.ANNOUNCE_WRITE}/${number}?requestCid=${this.docNo}`;
      this.$router.push(path);
      // this.movePageWithPath(`${this.$routerPath.ANNOUNCE_WRITE}/${number}?requestCid=${this.docNo}`);
    },
    async getNewAnnounceNumber() {
      const path = this.$apiPath.CREATE_ANNOUNCE_NUMBER;
      const result = await ApiService.shared.getData(path);
      if (!result.data) return null;

      const newNumber = result.data.postCid;
      return newNumber;
    },
    movePageWithPath(path) {
      const host = window.location.hostname;

      const isLocal = host === "localhost";
      const isDev = host.indexOf("dev-partner") > -1;
      const isProd = host.indexOf("pick-ke") > -1;

      if (isLocal) {
        window.location.href = `http://localhost:8080${path}`;
      }
      if (isDev) {
        // 개발

        window.location.href = `https://${host}${path}`;
      }
      if (isProd) {
        window.location.href = `https://${host}${path}`;
      }
    },
    onclickReg(item) {
      this.$router.push({
        name: "StatementWrite",
        query: { requestCid: this.docNo },
      });
    },
    onClickContractRegister() {
      const path = `${this.$routerPath.CONTRACT_WRITE}?requestCid=${this.docNo}`;
      this.$router.push(path);
    },
  },
};
</script>
